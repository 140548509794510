import { useEffect, useState } from "react";
import React from 'react';
import { Link } from "react-router-dom";
import Menu from "../Menu/Menu";
import { PrimaryMenu, SecondaryMenu } from "./NavigationData";
import styles from "./Navigation.module.scss";
import ButtonStyles from "../Button/Button.module.scss";
import logo from "../../logo-headline.svg";

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [navScrolled, setNavScrolled] = useState(false);

  const toggleHamburgerClasses = (open) => {
    const hamburgerBars = document.querySelectorAll(".burger__menu__bar");
    hamburgerBars.forEach((bar, index) => {
      bar.classList.toggle(`burger__menu__bar_${index + 1}-transform`, open);
      
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const currentScrollPos = window.scrollY;
      setTimeout(() => {
        navigationScroll(currentScrollPos);
      }, 250);
    });
  }, []);

  const navigationScroll = (currentScrollPos) => {
    currentScrollPos > 100 ? setNavScrolled(true) : setNavScrolled(false);
  };

  const toggleScroll = () => {
    if (window.innerWidth >= 1024) {
      return;
    }
    const body = document.querySelector("body");
    body.classList.toggle("no-scroll");
    
  };

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
    toggleHamburgerClasses(!menuOpen);
    toggleScroll(!menuOpen);
  };

  const closeMenu = () => {

    if (menuOpen) {
      toggleScroll(false);
    }
    
    setMenuOpen(false);
    toggleHamburgerClasses(false);
  };
  

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className={`${styles.navigation__wrapper} ${navScrolled ? styles.is_scrolled : ""} ${menuOpen ? styles.is_open : ""}`}>
      <div className={styles.container}>
        <div className={styles.nav__header}>
          <Link
            to='/'
            className={styles.nav__logo}
            onClick={(e) => {
              closeMenu();
              scrollToTop();
            }}
            aria-label='Go to the home page'>
            <img
              src={logo}
              className={styles.nav__logo__image}
              alt='CNA logo'
              width={500}
              height={62}
            />
          </Link>
          <button
            className={styles.burger__menu}
            onClick={handleMenuClick}
            aria-label={menuOpen ? "Close the main menu" : "Open the main menu"}>
            <div className={styles.burger__menu__bar}></div>
            <div className={styles.burger__menu__bar}></div>
            <div className={styles.burger__menu__bar}></div>
          </button>
        </div>
        <div className={`${styles.navigation} ${menuOpen ? styles.nav_open : styles.nav_closed}`}>
          <Menu
            items={PrimaryMenu}
            className={styles.nav || "nav"}
            listClassName={styles.nav__list}
            onCloseMenu={closeMenu}
          />
          <Menu
            items={SecondaryMenu}
            className={`${styles.nav || "nav"} ${styles.nav_secondary} ${styles.nav__button}`}
            listClassName={styles.nav__list}
            itemClassName={`${styles.nav__btn} ${ButtonStyles.btn_primary} ${styles.nav__button}`}
            onCloseMenu={closeMenu}
          />
        </div>
      </div>
    </div>
  );
};

export default Navigation;
