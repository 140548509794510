import React, { useEffect, useRef, useState } from "react";
import styles from "./Video.module.scss";
import Vimeo from "@vimeo/player";
import PlayButton from "../PlayButton/PlayButton";

const Video = ({ className }) => {
  const iframeRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (iframeRef.current) {
      const player = new Vimeo(iframeRef.current);

      player.on("play", function () {
        setIsPlaying(true);
      });

      player.on("pause", function () {
        setIsPlaying(false);
      });

      // player.getVideoTitle().then(function (title) {});
    }
  }, [iframeRef]);

  const handleTogglePlay = () => {
    const player = new Vimeo(iframeRef.current);
    if (isPlaying) {
      player.pause();
    } else {
      player.play();
    }
  };

  return (
    <div className={`${styles.video} ${className ? className : ""}`}>
      <div className={styles.video__wrapper}>
        <iframe
          ref={iframeRef}
          className={styles.video__iframe}
          src={`https://player.vimeo.com/video/1014700701?title=0&amp;`}
          allowFullScreen
          muted
          loop
          title='Comms National Awards'
          autoPlay='1'
          frameBorder='0'
          loading='lazy'></iframe>
      </div>

      <PlayButton handleTogglePlay={handleTogglePlay} isPlaying={isPlaying} />
    </div>
  );
};

export default Video;
