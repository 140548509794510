import React from "react";
import styles from './About.module.scss';
import Button from "../Button";
import {EVENT_NAME, EVENT_YEAR} from '../../Constants/constants'

const About = () => {
  return (
    <section id="about" className={styles.about}>

      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.title}>
          The comms sector’s largest awards process returns in {EVENT_YEAR} &ndash; be part of it!
          </div>
          <div className={styles.summary}>
            <p>We're calling for stand-out MSPs, channel partners, vendors, carriers, service providers and distributors to get the recognition their company and team deserves once again at the {EVENT_NAME}.</p>

            <p>With expanded categories recognising your commercial successes, technology innovation, engaging cultures and meaningful initiatives, the {EVENT_NAME} {EVENT_YEAR} will once again provide a vast range of opportunities for you to showcase your achievements among the very best our industry has to offer.</p>

            <p>Entry is free and the recognition and benefits are enormous. Come on, tell the world about all the great things you do!</p>

            <Button to={"/enter"}>Enter Now</Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;